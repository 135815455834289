<template>
  <section class="product-step">
    <header>
      <h2>{{step.title}}</h2>
    </header>
    <div class="container tex-center">
      <img v-lazy="step.image" class="hidden-xs" alt="" draggable="false">
      <img v-lazy="step.imageMobile" class="visible-xs" alt="" draggable="false">
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProductStep',
  props: {
    step: Object
  },
  data: () => ({
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss">
.product-step {
}
</style>
