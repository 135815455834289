<template>
  <div class="products container">
    <product-banner ref="banner" :prices="prices" @check="checkPrice"/>
    <product-words title="用戶證言" :has-bg="true"/>
    <product-step :step="step"/>
    <product-templates v-show="$route.query.type === 'diarybook'"/>
    <product-design :design="design" v-show="$route.query.type !== 'calendar'"/>
    <product-typeset :typeset="typeset" v-show="typeset.title"/>
    <product-materials :material="material"/>
    <product-prices :prices="prices" :symbols="symbols" v-if="$route.query.type === 'diarybook'"/>
    <production :production="production"/>
    <modal :show="showModal" :footer="true" @close="showModal = false">
      <div slot="body">
        <div class="text-center subtitle" style="margin-bottom: 1rem;">
          選擇其他國家/地區
        </div>
        <div class="row is-mobile">
          <div class="col-xs-6">
            <div class="control has-icons-left">
              <div class="select is-rounded">
                <select class="form-control" :value="locales" @change="changeLocale">
                  <option v-for="(item, index) in countries" :key="index" :value="item.alias">
                    {{item.label}}({{item.currency}})
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <button class="btn primary" @click="selectLocale">{{$t('button.ok')}}</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ProductWords from '../views/Home/Words'
import ProductBanner from '../components/Product/Banner'
import ProductStep from '../components/Product/Step'
import ProductTemplates from '../components/Product/Templates'
import ProductDesign from '../components/Product/Design'
import ProductTypeset from '../components/Product/Typesets'
import ProductMaterials from '../components/Product/Materials'
import ProductPrices from '../components/Product/Prices'
import Production from '../components/Product/Production'
import { find } from 'lodash'
import api from 'src/api'
import countries from '../../static/json/country'
import currencyMixin from '../mixins/currency'

export default {
  name: 'Products',
  mixins: [currencyMixin],
  components: {
    ProductBanner,
    ProductWords,
    ProductStep,
    ProductTemplates,
    ProductDesign,
    ProductTypeset,
    ProductMaterials,
    ProductPrices,
    Production
  },
  data: () => ({
    prices: null,
    locales: 'TW',
    symbols: 'NT$',
    tempLocale: null,
    showModal: false,
    countries
  }),
  computed: {
    step () {
      switch (this.$route.query.type) {
        case 'calendar':
          return {
            title: '5分鐘，製作您的2020年月曆',
            image: require('../../static/images/product/step-calendar.png'),
            imageMobile: require('../../static/images/product/step-mobile-calendar.png')
          }
        case 'photobook':
          if (this.$route.query.template === 'album') {
            return {
              title: '5分鐘，製作你的A4輕奢雜誌冊',
              image: require('../../static/images/product/step-album.png'),
              imageMobile: require('../../static/images/product/step-mobile-album.png')
            }
          }
          return {
            title: '5分鐘，你的照片一鍵成書',
            image: require('../../static/images/product/step-tablet-photobook.png'),
            imageMobile: require('../../static/images/product/step-mobile.png')
          }
        case 'diarybook':
        default:
          return {
            title: '5分鐘，你的臉書一鍵成書',
            image: require('../../static/images/product/step-tablet.png'),
            imageMobile: require('../../static/images/product/step-mobile.png')
          }
      }
    },
    typeset () {
      switch (this.$route.query.type) {
        case 'calendar':
          return {
            title: '横款/豎款自主選擇',
            subtitle: '我們提供了數2款模板供您選擇，讓您輕鬆製作自己的專屬作品',
            image: require('../../static/images/product/typeset-calendar.png'),
            imageMobile: require('../../static/images/product/typeset-mobile-calendar.png')
          }
        case 'photobook':
          if (this.$route.query.template === 'album') {
            return {
              title: '',
              subtitle: '',
              image: require('../../static/images/product/typeset-tablet-photobook.png'),
              imageMobile: require('../../static/images/product/typeset-mobile-photobook.png')
            }
          }
          return {
            title: '海量版式自主選擇',
            subtitle: '我們提供了數十款版式供您選擇，讓你輕鬆製作自己的專屬作品',
            image: require('../../static/images/product/typeset-tablet-photobook.png'),
            imageMobile: require('../../static/images/product/typeset-mobile-photobook.png')
          }
        case 'diarybook':
        default:
          return {
            title: '海量封面自主選擇',
            subtitle: '',
            image: require('../../static/images/product/typeset-tablet-diarybook.png'),
            imageMobile: require('../../static/images/product/typeset-mobile-diarybook.png')
          }
      }
    },
    material () {
      switch (this.$route.query.type) {
        case 'calendar':
          return {
            title: '工藝材質',
            subtitle: '我們提供2種不同的工藝包裝，每壹種都滿懷匠心',
            imageOne: require('../../static/images/product/material-calendar.png'),
            imageTwo: require('../../static/images/product/material-calendar-1.png'),
            imageOneMobile: require('../../static/images/product/material-mobile-calendar.png'),
            imageTwoMobile: require('../../static/images/product/material-mobile-calendar-1.png')
          }
        case 'photobook':
          if (this.$route.query.template === 'album') {
            return {
              title: '工藝材質',
              subtitle: '工藝包裝，滿懷匠心',
              imageOne: require('../../static/images/product/material-album.png'),
              imageTwo: require('../../static/images/product/material-2-album.png'),
              imageOneMobile: require('../../static/images/product/material-mobile-album.png'),
              imageTwoMobile: require('../../static/images/product/material-2-mobile-album.png')
            }
          }
          return {
            title: '工藝材質',
            subtitle: '我們提供2種不同的工藝包裝，每壹種都滿懷匠心',
            imageOne: require('../../static/images/product/material-5.jpg'),
            imageTwo: require('../../static/images/product/material-6.jpg'),
            imageOneMobile: require('../../static/images/product/material-7.jpg'),
            imageTwoMobile: require('../../static/images/product/material-8.jpg')
          }
        case 'diarybook':
        default:
          return {
            title: '工藝材質',
            subtitle: '工藝包裝，滿懷匠心',
            imageOne: require('../../static/images/product/material-1.jpg'),
            imageTwo: require('../../static/images/product/material-2.jpg'),
            imageOneMobile: require('../../static/images/product/material-3.jpg'),
            imageTwoMobile: require('../../static/images/product/material-4.jpg')
          }
      }
    },
    design () {
      switch (this.$route.query.type) {
        case 'calendar':
          return {
            title: '自定義封面／書名／內容',
            subtitle: '封面、書名自定義、臉書內容隨心更改。平凡生活的記錄、寶寶的成長日記、旅遊日記……這本書都可成為一個載體，承載你的點點滴滴。',
            image: require('../../static/images/product/design-tablet-photobook.png'),
            imageMobile: require('../../static/images/product/design-mobile-photobook.png')
          }
        case 'photobook':
          if (this.$route.query.template === 'album') {
            return {
              title: '產品參數',
              subtitle: ' ',
              image: require('../../static/images/product/params.png'),
              imageMobile: require('../../static/images/product/param-mobile-album.png')
            }
          }
          return {
            title: '自定義封面／書名／內容',
            subtitle: '封面、書名自定義、臉書內容隨心更改。平凡生活的記錄、寶寶的成長日記、旅遊日記……這本書都可成為一個載體，承載你的點點滴滴。',
            image: require('../../static/images/product/design-tablet-photobook.png'),
            imageMobile: require('../../static/images/product/design-mobile-photobook.png')
          }
        case 'diarybook':
        default:
          return {
            title: '自定義封面／書名／內容',
            subtitle: '封面、書名自定義、臉書內容隨心更改。平凡生活的記錄、寶寶的成長日記、旅遊日記……這本書都可成為一個載體，承載你的點點滴滴。',
            image: require('../../static/images/product/design-tablet-diarybook.png'),
            imageMobile: require('../../static/images/product/design-mobile-diarybook.png')
          }
      }
    },
    production () {
      switch (this.$route.query.type) {
        case 'calendar':
          return {
            title: '發貨',
            subtitle: '為了一份精致的時光，請給我們7個工作日打磨。\n' +
              '訂單高峰期，各道工序可能需等待，延長發貨時間，請各位諒解。',
            image: require('../../static/images/product/production-calendar.png'),
            imageMobile: require('../../static/images/product/production-mobile-calendar.png')
          }
        case 'photobook':
          if (this.$route.query.template === 'album') {
            return {
              title: '發貨',
              subtitle: '為了壹份精致的時光，請給我們7個工作日打磨。 訂單高峰期，各道工序可能需等待，延長發貨時間，請各位諒解。',
              image: require('../../static/images/product/production-album.png'),
              imageMobile: require('../../static/images/product/production-mobile-photobook.png')
            }
          }
          return {
            title: '發貨',
            subtitle: '為了壹份精致的時光，請給我們7個工作日打磨。 訂單高峰期，各道工序可能需等待，延長發貨時間，請各位諒解。',
            image: require('../../static/images/product/production-tablet-photobook.png'),
            imageMobile: require('../../static/images/product/production-mobile-photobook.png')
          }
        case 'diarybook':
        default:
          return {
            title: '發貨',
            subtitle: '為了壹份精致的時光，請給我們7個工作日打磨。 訂單高峰期，各道工序可能需等待，延長發貨時間，請各位諒解。',
            image: require('../../static/images/product/production-duration.png'),
            imageMobile: require('../../static/images/product/production-mobile-diarybook.png')
          }
      }
    }
  },
  watch: {
    locales: function (val) {
      if (val) {
        this.symbols = this.getSymbolByLocale(val)
        this.fetchPrice(true)
      }
    }
  },
  mounted () {
    this.locales = this.$store.state.locale
    this.tempLocale = this.$store.state.locale
    this.fetchPrice()
  },
  methods: {
    fetchPrice (reload) {
      if (reload) {
        this.prices = null
      }
      api.fetchPrice(this.locales).then(data => {
        if (this.$route.query.type !== 'diarybook') {
          this.prices = find(data, { priceType: this.$route.query.type }).value
        } else {
          const bookPrices = find(data, { priceType: 'book' }).value
          Object.keys(bookPrices).map(size => {
            Object.keys(bookPrices[size]).map(item => {
              bookPrices[size][item].inner = Number(bookPrices[size][item].inner[200])
            })
          })
          this.prices = bookPrices
        }
      })
    },
    startDraw () {
      if (this.$route.query.type === 'diarybook') {
        if (this.$store.state.user.uid) {
          this.$router.push('/app/shelf')
        } else {
          this.$router.push('/app/profile')
        }
        return
      }
      const binding = this.$refs.banner.$refs.sku.selected.binding || 'fabric'
      this.$router.push(`/setup?type=magazine&binding=${binding}`)
    },
    checkPrice () {
      this.showModal = true
    },
    changeLocale (evt) {
      this.tempLocale = evt.target.value
    },
    selectLocale () {
      this.locales = this.tempLocale
      this.showModal = false
    }
  }
}
</script>

<style lang="scss">
.products {
  overflow: hidden;
  padding-top: 50px;

  & > section {
    padding: 1rem 0;

    header {
      margin: 1.75rem 0 1.5rem;
      text-align: center;

      h2 {
        font-size: 1.5rem;
        font-weight: 500;
        margin: .5rem 0;
      }

      .subtitle {
        color: #333;
        font-size: 14px;
      }
    }

    img {
      max-width: 100%;
      margin: 0 auto;
    }

    & > .container {
      padding: 0 6rem;
    }
  }

  .title-meta {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    z-index: 30;
  }
}

@media (max-width: 768px) {
  .products {
    padding-bottom: 150px;

    & > section {
      & > .container {
        padding: 0 1rem;
      }
    }
  }
}
</style>
